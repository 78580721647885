<template>

  <v-app id="inspire">
    <v-navigation-drawer app
                         :color="$store.getters.settings.color.sideBar"
                         fixed
                         width="300"
                         clipped
                         v-if="utils.getLocalStorage('token')"
                         v-model="drawer">
      <v-list>
          <v-list-item color="blue"
              to="/domains"
              :class="'default-class elevation-6 px-1 pt-0 mt-2'"
              link>
            <v-list-item-title v-text="'Domains'" class="ml-3 white--text"></v-list-item-title>

          </v-list-item>

        <v-list-item color="blue"
              to="/mainBlock"
              :class="'default-class elevation-6 px-1 pt-0 my-2'"
              link>
            <v-list-item-title v-text="'Main Blocks'" class="ml-3 white--text"></v-list-item-title>

          </v-list-item>

        <v-list-item color="blue"
              to="/fileUpload"
              :class="'default-class elevation-6 px-1 pt-0 my-2'"
              link>
            <v-list-item-title v-text="'File Upload'" class="ml-3 white--text"></v-list-item-title>

          </v-list-item>

        <v-list-item color="blue"
              to="/filesDownload"
              :class="'default-class elevation-6 px-1 pt-0 my-2'"
              link>
            <v-list-item-title v-text="'Files Download'" class="ml-3 white--text"></v-list-item-title>

          </v-list-item>

        <v-list-item color="blue" v-if=""
              to="/createUser"
              :class="'default-class elevation-6 px-1 pt-0 my-2'"
              link>
            <v-list-item-title v-text="'Create User'" class="ml-3 white--text"></v-list-item-title>

          </v-list-item>

      </v-list>
      <!-- -->
<!--      <router-link to="/" class="ml-2 navbar-brand white&#45;&#45;text">Monoverse</router-link>-->
<!--      <div class="navbar-nav mr-auto">-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/tutorials" class="nav-link">Domains</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/add" class="nav-link  white&#45;&#45;text">Add Domain</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/addtemplate" class="nav-link  white&#45;&#45;text">Add Template</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/templatesList" class="nav-link  white&#45;&#45;text">Templates List</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/createCustomBlock" class="nav-link  white&#45;&#45;text">Create Custom Block (Test)</router-link>-->
<!--        </li>-->
<!--      </div>-->
    </v-navigation-drawer>

    <v-app-bar app color="purple" dark   clipped-left>
      <!-- -->
      <v-app-bar-nav-icon
          v-if="utils.getLocalStorage('token')" @click.prevent="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>

        <img width="100" height="35" src="@/assets/logo.png"/>
        <h6 class="ml-1">Admin Panel</h6>

      </v-app-bar-title>
      <v-spacer></v-spacer>

      <h5 class="mt-2"><i>{{ utils.getLocalStorage('username') ? utils.getLocalStorage('username') : '' }}</i></h5>

      <v-menu bottom
              offset-y
              origin="center center"
              transition="scale-transition"
      :disabled="!utils.getLocalStorage('token')">
        <template v-slot:activator="{ on }">

          <v-btn
              icon
              v-on="on">
            <v-icon>person</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              :key="i"
              @click="logout(item)"
              v-for="(item, i) in dropDownListItem">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>


    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>



        <!-- If using vue-router -->
        <router-view></router-view>

        <v-dialog max-width="600px" persistent v-model="dialog">
          <v-card>
            <v-card-title class="justify-center primary">
              <span class="headline white--text">{{'Change Password'}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col class="py-0" cols="12">
                      <v-text-field
                          :append-icon="showPassWord1 ? 'visibility' : 'visibility_off'"
                          :color="$store.getters.settings.color.mainColor"
                          label="New Password"
                          :type="showPassWord1 ? 'text' : 'password'"
                          @click:append="showPassWord1 = !showPassWord1"
                          prepend-inner-icon="lock"
                          required
                          v-model="password"></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                      <v-text-field
                          :append-icon="showPassWord2 ? 'visibility' : 'visibility_off'"
                          :color="$store.getters.settings.color.mainColor"
                          label="Confirm Password"
                          :type="showPassWord2 ? 'text' : 'password'"
                          @click:append="showPassWord2 = !showPassWord2"
                          prepend-inner-icon="lock"
                          required
                          v-model="repeatPassWord"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :color="$store.getters.settings.color.mainColor" @click="closeSettings" text>
                {{'close'}}
              </v-btn>
              <v-btn :color="$store.getters.settings.color.mainColor" @click="saveSettings" text>
                {{'save'}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>

    <LoadingModal :isLoading="$store.getters.loadingModal"/>
    <ErrorSnackBar v-if="$store.getters.snackBar"/>
  </v-app>



<!--  <v-app >-->
<!--    <nav class="navbar navbar-expand primary white&#45;&#45;text">-->
<!--      <router-link to="/" class="ml-2 navbar-brand white&#45;&#45;text">Monoverse</router-link>-->
<!--      <div class="navbar-nav mr-auto">-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/tutorials" class="nav-link  white&#45;&#45;text">Domains</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/add" class="nav-link  white&#45;&#45;text">Add Domain</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/addtemplate" class="nav-link  white&#45;&#45;text">Add Template</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/templatesList" class="nav-link  white&#45;&#45;text">Templates List</router-link>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <router-link to="/createCustomBlock" class="nav-link  white&#45;&#45;text">Create Custom Block (Test)</router-link>-->
<!--        </li>-->
<!--      </div>-->
<!--    </nav>-->

<!--    <div class="container mt-3">-->
<!--      <router-view />-->
<!--    </div>-->
<!--  </v-app>-->
</template>

<script>
import LoadingModal from "@/components/LoadingModal";
import ErrorSnackBar from "@/components/ErrorSnackBar";
import store from "@/store";
import requests from "@/requests";
import utils from "@/utilities";

export default {
  name: "app",
  components:{LoadingModal, ErrorSnackBar},
  data() {
    return {
      showPassWord1: false,
      showPassWord2: false,
      password: '',
      repeatPassWord: '',
      dialog: false,
      drawer: false,
      dropDownListItem: [],
      utils
    }},
  methods:{

    initSettingsFromLogin() {

      this.dropDownListItem = [
        {
          id: 1, title: 'Logout',
        },
        {
          id: 2, title: 'Change Password'
        }
      ]

      var settings = {
        color: {
          mainColor: 'primary',
          sideBar: 'purple',
          itemSideBar: 'secondary'
        }
      };
      this.$store.commit('setSettings', settings)
    },



    redirectLoginOrMainPage() {

      var token = utils.getLocalStorage('token');
      if (token != null) {
        this.$store.commit('setLoadingModal', true);
        requests.loginInformation(token, this.loginInformationCallBack);
      }
      else {
        this.$store.commit('setEnableRefreshListener', true);
        // this.$router.push({path: '/'})
      }
    },


    loginInformationCallBack(response) {

      utils.setLocalStorage('token', response.token);
      this.$store.commit('setLogin', response);
      this.$store.commit('setEnableRefreshListener', true);
      this.$store.commit('setLoadingModal', false);
      this.$router.push({path: '/'})
    },


    logout(item) {
      if (item.id == 1) {
        this.$store.commit('setLoadingModal', true);
        requests.logout('', this.logoutCallBack);
      }
      //    if item.id is 2 then is settings modal open
      else if (item.id == 2) {
        this.dialog = true;
      }
    },


    logoutCallBack(response) {

      utils.deleteLocalStorage('token');
      utils.deleteLocalStorage('username');
      this.$store.commit('setLoadingModal', false);
        this.$router.push({path: '/login'});

    },

    closeSettings() {
      this.dialog = false;
      this.showPassWord1 = false;
      this.showPassWord2 = false;
      this.$refs.form.reset();
    },


    changePassWordCallBack(response) {
      this.$store.commit('setLoadingModal', false);
      this.dialog = false;
      this.$refs.form.reset();
    },

    saveSettings() {
      if (this.password.trim() == '' && this.repeatPassWord.trim() == '') {
        alert('Password and Repeat Password are empty')
      } else if (this.password.trim() == '') {
        alert('Password is empty')
      } else if (this.repeatPassWord.trim() == '') {
        alert('repeat Password is empty')
      } else {
        if (this.password.trim() == this.repeatPassWord.trim()) {
          this.$store.commit('setLoadingModal', true);
          requests.changeMyPasword(this.$store.getters.login.token, this.password, this.changePassWordCallBack)
        } else {
          this.showPassWord1 = true;
          this.showPassWord2 = true;
          alert("it must be same ")
        }
      }
    },


  },
  created() {
    this.redirectLoginOrMainPage()
    this.initSettingsFromLogin();

    this.$store.commit('setSnackBarObj', {text: 'Hello Monoversian ', icon : 'mdi-alien'});
    this.$store.commit('setSnackBar', true)
  }
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap !important;
}

.centerScreen {
  bottom: 0;
  display: block;
  font-size: 32px;
  left: 50%;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
