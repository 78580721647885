import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"400","tile":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.domain.title))]),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.domain.description))]),_c(VListItemSubtitle,[_vm._v(" Description ")])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.domain.domainCategory ? _vm.domain.domainCategory.description : '-'))]),_c(VListItemSubtitle,[_vm._v(" Category ")])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[(_vm.domain.published)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v("check-circle")]):_c(VIcon,{attrs:{"color":"red"}},[_vm._v("stop-circle")])],1),_c(VListItemSubtitle,[_vm._v(" Published? ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }