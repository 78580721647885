import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"pt-1",attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VCard,{staticClass:"mt-4 elevation-4"},[_c(VForm,{ref:"form"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VTextField,{attrs:{"rules":_vm.userRules,"label":"Username","required":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VTextField,{attrs:{"rules":_vm.emailRules,"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VTextField,{attrs:{"rules":_vm.passwordRules,"label":"Password","type":"password","type":_vm.showPassWord ? 'text' : 'password',"append-icon":_vm.showPassWord ? 'visibility' : 'visibility_off',"required":""},on:{"click:append":function($event){_vm.showPassWord = !_vm.showPassWord}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.createUser}},[_vm._v("Create User")])],1)],1)],1)],1),_c(VRow,{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"pt-1",attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VDataTable,{staticClass:"elevation-3 mx-5 px-2",attrs:{"footer-props":{itemsPerPageOptions:[5,10,15,30]},"headers":_vm.headers,"items":_vm.$store.getters.users,"sort-by":"calories"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }