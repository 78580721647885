import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#600c70',
                secondary: '#F65275',
                goldColor: '#CBA052',
                greyColor: '#BDBDBD',
                lightSeaGreen: '#20b2aa',
                indianRed: '#cd5c5c',
            },
            dark: {
                primary: '#0D47A1',
                secondary: '#F65275',
                goldColor: '#CBA052',
                greyColor: '#BDBDBD'
            }
        },
    },
    dark: true,

        icons: {
            iconfont: "mdi",
            values: {
                myicon: {
                    component: "my-icon"
                },
                cowicon: {
                    component: "cow-icon"
                },
                vuetifyicon: {
                    component: "image-icon",
                    props: {
                        imgsrc: "https://vuetifyjs.com/favicon.ico"
                    }
                }
            }
        }

});
