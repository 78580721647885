<template>
  <div>
    <v-row class="mb-1">
      <v-col class="mx-auto text-center font-weight-light headline">
        <div>
          {{'Main Block'}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <v-data-table
            :footer-props="{itemsPerPageOptions:[5,10,15,30]}"
            :headers="headers"
            :items="$store.getters.mainBlocks"
            :search="search"
            class="elevation-3 mx-5 px-2"
            sort-by="calories"
        >
          <template v-slot:top>
            <v-toolbar flat>

              <v-card :color="$store.getters.settings.color.mainColor"
                      class="mb-5"
                      height="80"
                      width="90">
                <v-row align="center"
                       justify="center">
                  <v-col class=" mt-4  text-center white--text">
                    {{'Main Block'}}
                  </v-col>
                </v-row>
              </v-card>


              <v-toolbar-title>
                <br/>
                <v-text-field append-icon="mdi-magnify"
                              class="ml-5 mb-1"
                              hide-details
                              label="Search"
                              single-line
                              v-model="search"
                ></v-text-field>
              </v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog max-width="500px" persistent v-model="dialog">
                <template v-slot:activator="{ on }">

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn :color="$store.getters.settings.color.mainColor" @click="addButton"
                             class="mb-2" dark fab small
                             v-on="on">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{'Add'}}</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-title class="primary">
                    <span class="headline white--text">{{formTitle}}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field :color="$store.getters.settings.color.mainColor"
                                          :label="'Description'"
                                          v-model="description"></v-text-field>
                          </v-col>
                        </v-row><v-row>
                          <v-col cols="12">
                            <v-textarea :color="$store.getters.settings.color.mainColor"
                                        dense
                                        outlined
                                          :label="'Body'"
                                          v-model="body"></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.getters.settings.color.mainColor" @click="close" text>
                      {{'close'}}
                    </v-btn>
                    <v-btn :color="$store.getters.settings.color.mainColor" @click="save" text>
                      {{'save'}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="editItem(item)"
                        color="green"
                        small
                        v-on="on">edit
                </v-icon>
              </template>
              <span>{{'Edit'}}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="openModalToDeleteItem(item)"
                        color="red"
                        small
                        v-on="on">delete
                </v-icon>
              </template>
              <span>{{'delete'}}</span>
            </v-tooltip>


          </template>

        </v-data-table>
      </v-col>
    </v-row>


    <DeleteDialog eventName="MainBlockDelete"/>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import request from "@/requests";
import DeleteDialog from "@/components/reusable/DeleteDialog";

export default {
  name: "MainBlock",
  components: {DeleteDialog},
  data: () => ({
    description: '',
    body: '',
    valid: true,
    dialog: false,
    objToEdit: {},
    editedIndex: -1,
    search: '',
    deleteObj: {},
    headers: [

      {text: 'Main Block', value: 'description'},
      {text: 'Body', value: 'body'},
      {text: 'actions', value: 'action', sortable: false},
    ],
  }),

  methods: {

    addButton() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      }, 300)
    },

    createMainBlockObj() {


      return {
        description: this.description,
        body: this.body,

      }
    },

    save() {

      var token = '';

      if (this.valid) {

        console.log("valid main block");
        if (this.editedIndex > -1) {
          console.log("edit main block");
          //Edit user

          var mainBlock = this.createMainBlockObj();

          console.log("objid " + this.objToEdit.id);
          console.log("mainblock " + JSON.stringify(mainBlock));

          this.$store.commit('setLoadingModal', true);
          request.putRequest(token, 'main-block/'+this.objToEdit.id, mainBlock,  this.editMainBlockCallBack)
        } else {

          console.log("add main block");
          // Add new user
          this.$store.commit('setLoadingModal', true);
          request.postRequest(token, 'main-block/', this.createMainBlockObj(), this.addNewMainBlockCallBack);
        }
        // this.close()
      } else {

        console.log("snack bar main block");
        this.$store.commit('setSnackBarObj', {text: 'Empty Description'});
        this.$store.commit('setSnackBar', true);
      }
    },

    editMainBlockCallBack(response) {

      this.$store.commit('setMainBlocks', response);
      this.$store.commit('setLoadingModal', false);
      this.$refs.form.reset();
      this.close()
    },

    addNewMainBlockCallBack(response) {

      this.$store.commit('setMainBlocks', response);
      this.$store.commit('setLoadingModal', false);
      this.$refs.form.reset();
      this.close()
    },

    editItem(item) {
      this.description = item.description;
      this.body = item.body;
      this.objToEdit = item;
      this.editedIndex = this.$store.getters.mainBlocks.indexOf(item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.$store.commit('setLoadingModal', true);
      var token = '';
      request.deleteRequest(token, 'main-block/', this.deleteObj.id, this.deleteMainBlockCallBack);
    },

    deleteMainBlockCallBack(response) {

      this.$store.commit('setMainBlocks', response);
      this.$store.commit('setLoadingModal', false);
    },

    openModalToDeleteItem(item) {
      this.deleteObj = item;
      this.$store.commit('setDeleteDialog', true);
    },

    getMainBlock() {

      this.$store.commit('setLoadingModal', true);
      request.getRequest('', 'main-block/', '', this.getMainBlockCallBack);

    },

    getMainBlockCallBack(response) {

      this.$store.commit('setMainBlocks', response);
      this.$store.commit('setLoadingModal', false);
    },

  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'add' : 'edit'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.getMainBlock();

    eventBus.$on('MainBlockDelete', (() => {
      this.deleteItem();
    }));
  },
  beforeDestroy() {
    eventBus.$off('MainBlockDelete');
  }
}
</script>

<style scoped>

</style>
