<template>
  <div>
    <v-container>
    <v-row align="center" justify="center" class="mt-10">
      <v-col cols="6">
      <v-btn @click="downloadXlsx" min-width="100%" height="60px" color="primary" outlined>

        <v-progress-circular indeterminate v-if="isLoading"></v-progress-circular>
        <v-icon v-else color="primary">mdi-download</v-icon>
        Domains .xlsx
      </v-btn>
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import requests from "@/requests";

export default {
  name: "FilesDownload",
  data() {
    return {
      isLoading: false
    }
  },
  methods:{
    downloadXlsx(){

      this.isLoading = true;

      requests.downloadXlsxTemplate('', '/api/xlsx-file', this.downloadCallback);

    },
    downloadCallback(){
      this.isLoading= false;
    }
  }
}
</script>

<style scoped>

</style>