<template>
  <div>
    <v-app>
<!--    <div class="col-md-8">-->
<!--      <div class="input-group mb-3">-->
<!--        <input type="text" class="form-control" placeholder="Search by domain name"-->
<!--          v-model="title"/>-->
<!--        <div class="input-group-append">-->
<!--          <button class="btn btn-outline-secondary" type="button"-->
<!--            @click="searchTitle"-->
<!--          >-->
<!--            Search-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
      <v-row class="mt-6">
      <v-col class="pt-1">
    <v-data-table
        v-bind:key="tableKey"
        class="elevation-6 mx-5 px-2"
        :footer-props="{itemsPerPageOptions:[5,10,15,30]}"
        :items="$store.getters.domainsPaging"
        :headers="headers"
        :loading="loading"
        multi-sort
        :options.sync="options"
        @update:options="retrieveDomainsPage"
        :server-items-length="tableLength"
        :page.sync="page"
        @page-count="pageCount = $event">
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center d-sm-table-cell d-flex">{{ item.title!="" ? item.title : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.tld ? item.tld.description : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.mainBlock ? item.mainBlock.description : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.altBlock ? item.altBlock.description : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.blockOwner ? item.blockOwner.description : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.flat ? item.flat : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.house ? item.house : '--' }}</td>
            <td class="text-center d-sm-table-cell d-flex">{{ item.price!="" ? item.price : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">{{ item.description!="" ? item.description : '--' }}</td>
         <td class="text-center d-sm-table-cell d-flex">
            <v-btn @click="domainInfo(item)" icon small color="blue"><v-icon>info</v-icon></v-btn>
            <v-btn @click="settings(item)" icon small color="grey"><v-icon>settings</v-icon></v-btn>
<!--            <v-btn @click="edit(item)" icon small color="green"><v-icon>edit</v-icon></v-btn>-->

           <v-btn @click="editButton(item)" icon small color="green"><v-icon>edit</v-icon></v-btn>
            <v-btn @click="deleteItem(item)" icon small color="red"><v-icon>delete</v-icon></v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat>

          <v-card :color="$store.getters.settings.color.mainColor"
                  class="mb-5"
                  height="80"
                  width="90">
            <v-row align="center"
                   justify="center">
              <v-col class=" mt-4  text-center white--text">
                {{'Domains List'}}
              </v-col>
            </v-row>
          </v-card>
        <v-toolbar-title>
          <br/>
          <v-text-field
                        class="ml-5 mb-1"
                        append-icon="mdi-magnify"
                        hide-details
                        label="Search"
                        single-line
                        v-model="search"
          ></v-text-field>

        </v-toolbar-title>
          <v-spacer>
          </v-spacer>
          <v-btn @click="generateAll" text :disabled="generateAllLoading" color="orange">
            Generate all
            <v-icon v-if="generateAllLoading">loading</v-icon>
          </v-btn>
          <v-btn class="ml-2" @click="addButton"  color="blue" dark fab small>
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>

      </template>
    </v-data-table>
      </v-col>
      </v-row>
    <v-row>
      <v-col cols="4" class="mx-auto">
        <v-pagination color="`primary`" dark v-model="page" :length="pageCount"></v-pagination>
      </v-col>
    </v-row>



    <v-dialog @keydown.esc="closeDialog" fullscreen v-if="selectedItem.hasOwnProperty('title')" max-width="900px" persistent v-model="editTemplateCondition">
      <v-card color="purple">
        <v-col cols="1" aling="right">
          <v-btn icon @click="closeDialog"><v-icon color="primary">close</v-icon></v-btn>
        </v-col>




        <v-card-text>
          <v-row align="center" justify="center">
          <v-col cols="9">

            <v-row align="center"
                   justify="center">
              <v-col cols="4"><h3 align="center">{{selectedItem.title}}</h3></v-col>
            </v-row>


          <v-card tile color="white lighten-5" class="elevation-20 mb-10 mt-5" v-for="(boxedContainer, index) in $store.getters.boxedContainersForEditing" :key="index">

            <v-card
                color="black accent-6"
                    class="mb-2"
                    height="80"
                    width="250">
              <v-row  align="center"
                      justify="center">
                <v-col class="mt-4  text-center white--text">
                  <h5>{{boxedContainer.value}}</h5>
                </v-col>
              </v-row>
            </v-card>
            <v-row  align="center"
                    justify="center">
              <v-col cols="3">
              <v-select
                  :items="boxedContainer.gridList"
                  :height="40"
                  v-model="boxedContainer.grid"
                  label="Select Grid"
                  item-text="name"
                  chips
                  outlined
                  return-object
                  persistent-hint>

              </v-select>
              </v-col>
            </v-row>

            <v-row class="mt-1" v-for="(field, indexField) in boxedContainer.fields" :key="indexField">
              <v-col class="text-center d-sm-table-cell d-flex" cols="2">
<!--                <h3>{{field.fieldType.description}}</h3>-->
                <v-icon large color="primary" v-if="field.fieldType.id === 1">image</v-icon>
                <v-icon class="mt=5" large color="primary" v-else-if="field.fieldType.id === 2">palette</v-icon>
                <v-icon large color="primary" v-else-if="field.fieldType.id === 3">message</v-icon>
                <v-icon large color="primary" v-else-if="field.fieldType.id === 4">link</v-icon>
              </v-col>

              <v-col class="text-center d-sm-table-cell d-flex" v-if="field.fieldType.id !==1 && field.fieldType.id !==2" cols="4">

                <v-text-field
                    label="Value"
                    v-model="field.value">
                </v-text-field>
              </v-col>
              <v-col class="text-center d-sm-table-cell d-flex" cols="4" v-else-if="field.fieldType.id === 1" align="center" justify="center">
                <v-file-input
                    v-model="field.file"

                    clearable
                    chips
                    placeholder="Upload your image"
                    label="File input"
                    :success-messages="field.messageFile"
                    prepend-icon="mdi-file-excel"
                    append-outer-icon="info"
                    :loading="field.fileLoading"

                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                        small
                        label
                        color="primary"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <v-btn text :disabled="field.file==null" @click="submitFile(field)">submit</v-btn>

                <v-img :src="url(field)" />
              </v-col>
              <v-col  cols="4" v-else-if="field.fieldType.id === 2" align="center" justify="center">
                <v-color-picker
                    canvas-height="80"
                    hide-mode-switch
                    dot-size="15"
                    swatches-max-height="50"
                    mode="hexa"
                    v-model="field.value"
                ></v-color-picker>
              </v-col>
              <v-col class="text-center d-sm-table-cell d-flex" cols="2">

              </v-col>
              <v-col class="text-center d-sm-table-cell d-flex"  cols="2" v-if="field.fieldType.id !== 1">
                <v-row align="center" justify="center">

                  {{field.domainValue}}
                  <svg class="ml-10" v-if="field.fieldType.id === 2" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" :fill="field.domainValue" />
                  </svg>
                </v-row>


              </v-col>
<!--              <v-col class="text-center d-sm-table-cell d-flex"  cols="2" v-else></v-col>-->
              <v-col  class="justify-center" cols="2" v-else-if="field.fieldType.id === 1">
                <v-spacer></v-spacer>
                <v-btn
                        block
                       color="primary"
                       fab
                        icon
                       :disabled="field.fileId == null"
                       @click="openPhotosFunc(field.fileId)">

                  <v-icon>image</v-icon>
                </v-btn>

              </v-col>

              <v-divider></v-divider>
            </v-row>

          </v-card>
          </v-col>
          </v-row>


        </v-card-text>
        <v-card-actions>
          <v-col>
            <v-btn @click="saveBoxes()" color="primary">Save</v-btn>
            <v-btn @click=" generateWebsite()" color="orange">generate</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>






    <v-dialog @keydown.esc="closeSettingsDialog" fullscreen max-width="900px" persistent v-model="editSettingsCondition">
      <v-card>
        <v-row>
          <v-col cols="11"></v-col>
        <v-col cols="1" aling="right">
          <v-btn @click="closeSettingsDialog" icon><v-icon color="primary">close</v-icon></v-btn>
        </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="2">
        <v-card-title>{{'Domain Settings'}}</v-card-title>
          </v-col>
        </v-row>


        <v-card-text>

            <v-container fluid justify-center>
              <v-flex class="elevation-1 pa-3 ma-2">
                <v-toolbar class="mb-2" color="primary" dark>
                  <v-toolbar-title>Document Types</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="action('edit')" v-if="!editing">
                    <v-icon>sort</v-icon>
                  </v-btn>
                  <v-btn icon @click="action('done')" v-if="editing">
                    <v-icon>done</v-icon>
                  </v-btn>
                  <v-btn icon @click="action('undo')" v-if="editing">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list two-line>
                  <draggable v-bind="options2" v-model="boxedItems">
                    <template v-for="(v, i) in boxedItems">
                      <v-list-item :key="v.position" avatar >
                        <v-list-item-avatar color="grey">
                          <span>{{ i+1 }}</span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="v.value"></v-list-item-title>
<!--                          <v-list-item-subtitle v-html="v.Subtitle"></v-list-item-subtitle>-->
                        </v-list-item-content>
                        <!--
                                          <v-list-item-action v-if="editing">
                                            <v-btn @click="remove(i)" icon><v-icon>close</v-icon></v-btn>
                                          </v-list-item-action>
                        -->
                        <v-switch class="ml-4"
                                  v-model="v.enabled"
                        >
                          <template v-slot:label>
                            <div>
                              Enabled?
                              <v-icon v-if="v.enabled" color="green">done</v-icon>
                              <v-icon v-else color="orange">remove</v-icon>
                            </div>
                          </template>
                        </v-switch>
                        <v-btn @click="deleteBoxedContainerItem(v, i)" icon><v-icon>delete</v-icon></v-btn>
                      </v-list-item>
                    </template>
                  </draggable>
                </v-list>
              </v-flex>
            </v-container>


          <v-btn @click="saveSettings()" color="primary">Save</v-btn>


        </v-card-text>
      </v-card>
    </v-dialog>




    <v-dialog @keydown.esc="openPhotos = false" max-width="900px" persistent v-model="openPhotos"
              v-if="selectedImageId != null">
      <v-card>
        <v-card-title class="primary">
          <v-spacer></v-spacer>
          <v-icon color="white" @click="openPhotos = false;">clear</v-icon>
        </v-card-title>
        <v-card-text>
          <v-system-bar lights-out>

          </v-system-bar>
          <v-img
                 position="center center"
                 transition="true"
                 max-height="600"
                 max-width="500"
                 :src="returnImagePath()">
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>




    <v-dialog @keydown.esc="closeDomainInfo()" max-width="900px" persistent v-model="showDomainInfo"
             >
      <v-card>
        <v-card-title class="primary">
          <v-spacer></v-spacer>
          <v-icon color="white" @click="closeDomainInfo()">clear</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <DomainInfo :domain="selectedDomainInfo"/>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <AddTutorial :key="keyCounter"  :editedIndex="editedIndex" :disableFields="disableFields"/>



    </v-app>
  </div>


</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
import requests from '../requests'
import draggable from 'vuedraggable'
import DomainInfo from "@/components/reusable/DomainInfo";
import AddTutorial from "@/components/AddTutorial";
import {eventBus} from "@/main";
export default {
  name: "tutorials-list",
  components: {
    AddTutorial,
    draggable,
    DomainInfo
  },
  data() {
    return {
      disableFields: false,
      editedIndex: -1,
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      title: "",
      editTemplateCondition:false,
      header1:'',
      selectedItem: {},
      domain: {},
      myFields:[],
      requestObj:{},
      page: 1,
      pageCount: 0,
      tableLength: 0,
      loading: false,
      options:{},
      editSettingsCondition:false,
      domainObject:{},
      headers:[
        {text: 'Domain Name', value: 'title', sortable: true, align: 'center'},
        {text: 'TLD', value: 'tld.description', sortable: true, align: 'center'},
        {text: 'Main Block', value: 'mainBlock.description', sortable: true, align: 'center'},
        {text: 'Alt Block', value: 'altBlock.description', sortable: true, align: 'center'},
        {text: 'Block Owner', value: 'blockOwner.description', sortable: true, align: 'center'},
        {text: 'is Flat?', value: 'flat', sortable: true, align: 'center'},
        {text: 'is House?', value: 'house', sortable: true, align: 'center'},
        {text: 'Price', value: 'price', sortable: false, align: 'center'},
        {text: 'Description', value: 'description', sortable: false, align: 'center'},
        {text: 'Actions', value: 'actions', sortable: false, align: 'center'},

      ],

      fileLoading: false,
      fileLoadings: [],
      messageFiles: [],
      file:null,
      search:'',
      before: null,
      editing: false,
      boxedItems: [],
      openPhotos:false,
      selectedImageId:null,
      showDomainInfo:false,
      selectedDomainInfo:{},
      keyCounter : 0,
      tableKey:0,
      generateAllLoading: false
    };
  },
  methods: {
    settings(item){

      this.getBoxedContainers(item.template);

    },

    saveSettings(){
      console.log(this.boxedItems)

      requests.postRequest('', 'boxed-container/set-list-positions/', this.boxedItems, this.saveSettingsCallback)
      this.$store.commit('setLoadingModal', true);

    },
    saveSettingsCallback(){
      this.closeSettingsDialog();

    },

    deleteBoxedContainerItem(item, index) {
      this.boxedItems.splice(index, 1);
    },

    getBgColor(field, indexField){
      if(this.$store.getters.fields[indexField-1]
          && this.$store.getters.fields[indexField-1].boxedContainer
          && this.$store.getters.fields[indexField-1].boxedContainer.id !== field.boxedContainer.id){
        return `border-top: 5px solid red;`;
      }
      else{
        return ``
      }
    },

    onChangeField(field){
      let obj = {};
      obj.field = field;
      // obj.value = val

    },
    retrieveDomainsPage(item){

      this.$store.commit('setLoadingModal',true);

      this.requestObj.pagingConfig = {

        pagingEnable: true,
        pagingPage: item.page - 1,
        pagingSize: item.itemsPerPage,
        shortConfig:{
          shortItems: [
            {
              shortType:'ASC',
              shortColumn: 'id'
            }
          ]
        }

      };

      requests.postRequest('', 'api/domains-pagination/', this.requestObj, this.retrieveDomainsPageCallBack)
    },
    retrieveDomainsPageCallBack(response) {
      //this.workOrders = response.items;
      this.$store.commit('setDomainsPaging',response.items);
      this.tableLength = response.length;
      this.$store.commit('setLoadingModal',false);
      this.loading=false;
    },


    submitFile(field) {
      field.messageFile ='';
      field.fileLoading= true;
      /*
        Initialize the form data
      */
      let formData = new FormData();

      formData.append('file', field.file);

      formData.append('fieldId', field.id);
      formData.append('domainId', this.selectedItem.id);
      // for (var i = 0; i < this.files.length; i++) {
      //     let file = this.files[i];
      //     formData.append('files[' + i + ']', file);
      // }

      var token = this.$store.getters.login.token;
      requests.uploadFilePostAsync(token, 'upload-file/file-on-domain-field', formData, this.uploadFilePostCallBack)
    },
    uploadFilePostCallBack(response) {
      this.fileLoading=false;
      this.messageFile = 'Added ' +response+ ' new records';
      this.file=null;
    },




    retrieveTutorials() {

      ///// old library for request
      TutorialDataService.getAll()
        .then(response => {
          this.tutorials = response.data;
          // console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    refreshList() {
       this.retrieveTutorials();
      this.currentTutorial = null;
      this.currentIndex = -1;
    },

    setActiveTutorial(tutorial, index) {
      this.currentTutorial = tutorial;
      this.currentIndex = index;
    },

    removeAllTutorials() {
      TutorialDataService.deleteAll()
        .then(response => {
          console.log(response.data);
          this.refreshList();
        })
        .catch(e => {
          console.log(e);
        });
    },

    searchTitle() {
      TutorialDataService.findByTitle(this.title)
        .then(response => {
          this.tutorials = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    edit(item){
      console.log("edit")
      this.selectedItem= item
      if(item.template){
        this.domain.template =item.template;
        var token = '';
        requests.getRequest(token, 'boxed-container/by-template-with-position-only-enabled-sorted/', item.template.id, this.getBoxedContainersForEditingCallback);
      }

      this.editTemplateCondition = true
    },
    deleteItem(item){
      this.$store.commit('setLoadingModal',true);
      var token = '';
      requests.deleteRequest(token, 'api/domain/'+item.id ,this.deletecallBack);
    },
    deletecallBack(response){
      // this.retrieveDomainsPage();
      requests.postRequest('', 'api/domains-pagination/', this.requestObj, this.retrieveDomainsPageCallBack)
      this.$store.commit('setLoadingModal',false);
    },
    closeDialog(){
      this.editTemplateCondition = false
    },
    closeSettingsDialog(){
      this.editSettingsCondition = false
      this.$store.commit('setLoadingModal', false);
    },
    getFK(){
      this.$store.commit('setLoadingModal',true);
      var token = '';
      requests.getRequest(token, 'template', '' ,this.getFKCallBack);
    },
    getFKCallBack(response) {
      this.$store.commit('setTemplates',response);

      this.$store.commit('setLoadingModal',false);
    },


    getBoxedContainers(item){
      this.$store.commit('setLoadingModal',true);
      var token = '';
      requests.getRequest(token, 'boxed-container/by-template-with-position/',item.id  ,this.getBoxedContainerCallBack);
    },


    getBoxedContainerCallBack(response) {
      this.$store.commit('setBoxedContainers',response);
      this.boxedItems = response;
      this.editSettingsCondition=true;

      this.$store.commit('setLoadingModal',false);
    },
    getBoxedContainersForEditingCallback(response){
      this.$store.commit('setBoxedContainersForEditing',response);
      this.editTemplateCondition = true;

      this.$store.commit('setLoadingModal',false);
    },
    getFieldCallBack(response) {
      this.$store.commit('setFields',response);
      this.myFields = [];
      for(var i=0; i<response.length;i++){
        this.myFields[i] = {
          value: '',
          field: response[i]
        }
      }
    },

    save(){
      console.log(this.myFields);
      var token = '';
      var obj = {};
      obj.id=this.selectedItem.id
      obj.domainFieldObjs = this.myFields
      obj.template = this.domain.template
      obj.title = this.selectedItem.title
      obj.descritpion = ''
      obj.published = true
      requests.putRequest(token, 'api/domain/' + this.selectedItem.id, obj, this.getFieldCallBack);
      this.saveCallBack()
    },
    saveBoxes(){
      let obj = {}
      obj.list = this.$store.getters.boxedContainersForEditing;
      obj.domainId = this.selectedItem.id;
      requests.postRequest('', 'template/edit-template/', obj, this.saveCallBack());

    },


    saveCallBack(){
      this.closeDialog();

      this.$store.commit('setLoadingModal',false);
    },
    generateWebsite(){
      this.$store.commit('setLoadingModal',true);
      var token = '';
      var obj = {};
      obj.domainId = this.selectedItem.id
      requests.postRequest(token, 'api/generate-website/', obj, this.postGenerateWebsiteCallback);

    },
    postGenerateWebsiteCallback(response){
      this.$store.commit('setLoadingModal',false);
      this.closeDialog();

    },
    updateTemplate(val){
      var token = '';
      requests.getRequest(token, 'field/by-template/', val.id, this.getFieldCallBack);
    },
    action (e) {
      if (e === 'edit') this.before = Object.assign([],this.items)
      if (e === 'undo') this.items = this.before
      this.editing = !this.editing
    },
    remove (i) {
      this.$delete(this.items, i)
    },
    url(field) {
      if (!field.file) return;
      return URL.createObjectURL(field.file);
    },

    openPhotosFunc(id){
      this.BASE_URL = process.env.VUE_APP_API_ENDPOINT;
      this.openPhotos = true;
      this.selectedImageId = id

    },
    returnImagePath() {

      return this.BASE_URL + '/download-file/file/'+this.selectedImageId;
    },
    domainInfo(item){
      this.selectedDomainInfo = item;
      this.showDomainInfo=true;
    },
    closeDomainInfo(){

      this.showDomainInfo=false;

    },
    addButton() {
      this.disableFields = false;
      eventBus.$emit('createDomainObj', {});
      this.editedIndex = -1;
      this.$store.commit('setEditOrAddDomainModal', true);
    },

    generateAll(){

      this.generateAllLoading = true
      requests.postRequest('', 'api/generate-all-websites/', {}, this.generateAllCallBack)
    },

    generateAllCallBack(response){
      this.generateAllLoading = false
    },

    editButton(item){


      let searchObj = {}//this.searchObj;
      eventBus.$emit('createDomainObj', item, searchObj);
      this.domainObject = item;
      this.editedIndex = 0;
      this.disableFields = false;
      this.$store.commit('setEditOrAddDomainModal', true);
    },


  },

  watch:{
    'domain.template': function(val) {
      console.log("domain.template");
      if(val ) {
        var token = '';
        requests.getRequest(token, 'field/by-template/', val.id, this.getFieldCallBack);
      }
  },
    'search': function (val){

        this.requestObj.freeText = val;
        requests.postRequest('', 'api/domains-pagination/', this.requestObj, this.retrieveDomainsPageCallBack)

    },
    '$store.getters.editOrAddDomainModal': function (val){

        this.tableKey++;

    }
  },
  mounted() {
   // this.retrieveTutorials();
  },
  computed: {
    options2 () {
      return {
        disabled: !this.editing
      }
    },


  },
  created() {
    // this.getFK();




  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.draggable-flex {
  display: inherit;
  flex-direction: inherit;
}

html, body, .v-application, .v-application--wrap {
  min-height: -webkit-fill-available;
}
</style>
