<template>
  <v-app id="inspire" :style="{ background: activeColor}">
    <v-main>
      <v-container>

        <v-row align="center" justify="center" class="mt-10">
          <v-col cols="4">
            <img :width="300" src="./../assets/logo.png"/>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <v-card  class="elevation-0" :style="{ background: activeColor}" v-if="showTextFields">
              <v-card-text>
                <v-form ref="form">
                  <v-text-field @keyup.enter="login()"
                                :color="$store.getters.settings.color.mainColor"
                                prepend-inner-icon="person"
                                ref="name"
                                v-model="username"
                                :label="'username'"
                                required
                  ></v-text-field>
                  <v-text-field @keyup.enter="login()"
                                :color="$store.getters.settings.color.mainColor"
                                prepend-inner-icon="lock"
                                v-model="password"
                                :label="'password'"
                                :type="showPassWord ? 'text' : 'password'"
                                :append-icon="showPassWord ? 'visibility' : 'visibility_off'"
                                @click:append="showPassWord = !showPassWord"
                                required
                  ></v-text-field>
<!--                  <v-text-field v-if="!singleCompany" @keyup.enter="login()"-->
<!--                                :color="$store.getters.settings.color.mainColor"-->
<!--                                prepend-inner-icon="supervised_user_circle"-->
<!--                                ref="name"-->
<!--                                v-model="creator"-->
<!--                                :label="'creatorLabel'"-->
<!--                                required-->
<!--                  ></v-text-field>-->
                </v-form>
              </v-card-text>

              <v-btn v-if="true" dark :color="$store.getters.settings.color.mainColor" rounded block @click="login()">{{ 'login' }}</v-btn>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>



<script>

import request from '../requests';
import utils from '@/utilities.js';


export default {
  name: "Login",
  data: () => ({
    utils,
    activeColor: '#f5f5f5',
    username: '',
    password: '',
    creator: '',
    showPassWord: false,
    singleCompany: false,
    showTextFields: false,
  }),
  methods: {

    loginValidation() {
      if (this.username.replace(/\s/g,'') == '' && this.password.replace(/\s/g,'') == ''){
        this.$store.commit('setSnackBarObj', {text: 'emptyUsernameAndPassword'});
        this.$store.commit('setSnackBar', true);
        return false;
      }
      else if (this.username.replace(/\s/g,'') == '') {
        this.$store.commit('setSnackBarObj', {text: 'emptyUsername'});
        this.$store.commit('setSnackBar', true);
        return false;
      }
      else if (this.password.replace(/\s/g,'') == '') {
        this.$store.commit('setSnackBarObj', {text: 'emptyPassword'});
        this.$store.commit('setSnackBar', true);
        return false;
      }
      else {
        return true;
      }
    },

    login() {
      if (this.loginValidation()) {
        this.$store.commit('setLoadingModal' , true);
        request.login(this.username.replace(/\s/g,''), this.password.replace(/\s/g,''),   this.loginCallBackSuccess);
        // this.$router.push({path: '/mainAppPage'});
      }
    },
    loginCallBackSuccess(response){
      this.$store.commit('setLoadingModal' , false);
      if (response != '') {
        utils.setLocalStorage('token', response.token);
        utils.setLocalStorage('username', response.username);
        this.$store.commit('setLogin', response);
        this.$router.push({path: '/domains'});
      }
    },

    //check if is dark mode
    checkIfisDarkMode() {

      if (this.$vuetify.theme.dark) {
        this.activeColor = '';
        return
      }
      this.activeColor = '#F5F5F5'
    },

    initGuiConfig() {
      this.showTextFields = true;
    },

    getInitGuiConfigCallback(response) {


    }
  },
  created() {

    this.$store.commit('setLogin', '');
    this.checkIfisDarkMode();
    this.initGuiConfig();
  }
}
</script>

<style scoped>

</style>
