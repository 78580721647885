<template>
<div>
    <v-row align="center" justify="center" class="mt-10">
      <v-col cols="4">
  <img :width="500" src="./../assets/logo.png"/>
      </v-col>
    </v-row>
</div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>