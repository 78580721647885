<template>
  <div  data-app>
    <v-card  class="elevation-3 mb-2">
      <v-card-title class="primary white--text" align="center" justify="center">
        <v-col class="mx-1"> New Template</v-col>
      </v-card-title>

      <v-card-text class="mt-2" align="center" justify="center">
        <v-row align="center" justify="center">
          <v-col cols="3">
        <v-text-field
            outlined
            label="Template Title"
          v-model="template.description">

      </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-switch class="ml-4"
                    v-model="hasBackgroundColor"
          >
            <template v-slot:label>
              <div>
                Background Color?
                <v-icon v-if="hasBackgroundColor" color="green">done</v-icon>
                <v-icon v-else color="orange">remove</v-icon>
              </div>
            </template>
          </v-switch>
        </v-row>
        <v-row v-if="hasBackgroundColor"  align="center" justify="center">
          <v-col cols="3">
            {{'Background Color:'}}
            <v-color-picker
                dot-size="25"
                swatches-max-height="200"
                mode="hexa"
                v-model="template.color"
            ></v-color-picker>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

        <v-btn icon v-if="!mustAddItem" @click="mustAddItem=true"><v-icon color="primary">add</v-icon></v-btn>


        <v-card class="mx-2 mt-2" color="cyan lighten-4" v-for="(boxedContainer, index_bc) in boxedContainers" :key="index_bc">
          <v-card-title>Box {{ index_bc+1 }}</v-card-title>
          <v-col cols="2">
          </v-col>
          <v-card-text>
          <v-row v-for="(field, index) in boxedContainer.fields" :key="index">
            <v-col cols="3">
              <v-select
                  :items="$store.getters.fieldTypes"
                  :height="40"
                  label="Select Type"
                  v-model="boxedContainer.fields[index].fieldType"
                  :item-text="item => item.title +' - '+ item.description"
                  chips
                  outlined
                  return-object
                  persistent-hint>

              </v-select>
            </v-col>
            <v-col>
              <v-text-field
                  label="Description"
              v-model="boxedContainer.fields[index].description">
              </v-text-field>
            </v-col>
            <v-col v-if=" boxedContainer.fields[index].fieldType  && boxedContainer.fields[index].fieldType.id !== 3">
              <v-text-field
                  label="Value"
              v-model="boxedContainer.fields[index].value">
              </v-text-field>
            </v-col>
            <v-col v-else-if=" true && boxedContainer.fields[index].fieldType && boxedContainer.fields[index].fieldType.id == 3" align="center" justify="center">
              <v-file-input
                  v-model="file"
                  clearable
                  chips
                  placeholder="Upload your document"
                  label="File input"
                  :success-messages="messageFile"
                  prepend-icon="mdi-file-excel"
                  append-outer-icon="info"
                  :loading="fileLoading"

              >
                <template v-slot:selection="{ text }">
                  <v-chip
                      small
                      label
                      color="primary"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-btn text :disabled="file==null" @click="submitFile()">submit</v-btn>
            </v-col>

            <v-col cols="2">
              <v-select
                  :items="positionsList"
                  :height="40"
                  label="Select Position"
                  v-model="boxedContainer.fields[index].position"
                  item-text="position"
                  chips
                  outlined
                  return-object
                  persistent-hint>

              </v-select>
            </v-col>


            <v-col>
            <v-btn @click="removeField(boxedContainer, index)" icon color="red"><v-icon>delete</v-icon></v-btn>
            </v-col>
          </v-row>
            <v-btn @click="addField(boxedContainer)" text color="primary">add field</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="removeBoxedContainer(index_bc)"><v-icon>remove</v-icon></v-btn>
          </v-card-actions>
        </v-card>

    <v-row class="mt-2" align="center" justify="center">
      <v-col cols="3">
    <v-btn  @click="addBoxedContainer">add boxed container</v-btn>
      </v-col>

      <v-col cols="3">
    <v-btn  @click="saveTemplate" color="primary white--text">save</v-btn>
      </v-col>
    </v-row>

    </div>
</template>

<script>

import requests from '../requests'
export default {
  name: "add-tutorial",
  data() {
    return {
      fields:[{}],
      boxedContainers:[],
      template:{},
      mustAddItem:false,
      tutorial: {
        id: null,
        title: "",
        description: "",
        content: "",
        published: false
      },
      submitted: false,
      fileLoading: false,
      messageFile:null,
      file:null,
      hasBackgroundColor:false,
      positionsList: ['center', 'left', 'right']
    };
  },
  methods: {

    getFK(){
      requests.getRequest('','field-type', '', this.getFieldTypesCallback);
    },
    removeField(boxedContainer, index){
      boxedContainer.fields.splice(index, index);
    },
    addField(boxedContainer){
      if(boxedContainer.fields.length<6) {
        boxedContainer.fields.push({});
      }
    },
    addBoxedContainer(){
      let boxedContainer = {}
      let fields = [];
      boxedContainer.fields=fields;
      if(this.boxedContainers.length<6) {

        this.boxedContainers.push(boxedContainer);
      }
    },
    removeBoxedContainer(index){
      this.fields.splice(index, index);
    },
    getFieldTypesCallback(response){
      this.$store.commit('setFieldTypes', response);
    },
    saveTemplate(){
      let obj = {};
      obj.template = this.template;
      obj.boxedContainers =this.boxedContainers;
      requests.postRequest('', 'template/save-template', obj, this.saveTemplateCallback)

    },
    saveTemplateCallback(response){

    },

    submitFile() {
      this.messageFile ='';
      this.fileLoading= true;
      /*
        Initialize the form data
      */
      let formData = new FormData();

      formData.append('file', this.file);

      formData.append('fieldId', 15);
      // for (var i = 0; i < this.files.length; i++) {
      //     let file = this.files[i];
      //     formData.append('files[' + i + ']', file);
      // }

      var token = this.$store.getters.login.token;
      requests.uploadFilePostAsync(token, 'upload-file/file', formData, this.uploadFilePostCallBack)
    },
    uploadFilePostCallBack(response) {
      this.fileLoading=false;
      this.messageFile = 'Added ' +response+ ' new records';
      this.file=null;
    },

  },
  watch: {
    'template.color': function (val){
      console.log(val);
    }
  },
  created() {
    this.getFK();
  }
}
</script>

<style>

</style>
