<template>
  <div>
    <v-row class="mt-2" align="center" justify="center"><v-col   cols="12" sm="10" md="8" lg="6" class="pt-1">

    <v-card class="mt-4 elevation-4">
      <v-form ref="form">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <v-text-field
                v-model="user"
                :rules="userRules"
                label="Username"
                required
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                type="password"
                :type="showPassWord ? 'text' : 'password'"
                :append-icon="showPassWord ? 'visibility' : 'visibility_off'"
                @click:append="showPassWord = !showPassWord"
                required
            />
          </v-col>
        </v-row>
<!--        <v-row align="center" justify="center">-->
<!--          <v-col cols="12" sm="10" md="8" lg="6">-->

<!--            <v-select color="primary"-->
<!--                      :items="$store.getters.roles"-->
<!--                      :label="'Roles'"-->
<!--                      chips-->
<!--                      outlined-->
<!--                      :height="40"-->
<!--                      multiple-->
<!--                      item-text="description"-->
<!--                      persistent-hint-->
<!--                      v-model="roles"-->
<!--            ></v-select>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="createUser">Create User</v-btn>
      </v-card-actions>
    </v-card>

    </v-col></v-row>
    <v-row class="mt-2" align="center" justify="center">
      <v-col  cols="12" sm="10" md="8" lg="6" class="pt-1">

        <v-data-table
            :footer-props="{itemsPerPageOptions:[5,10,15,30]}"
            :headers="headers"
            :items="$store.getters.users"
            class="elevation-3 mx-5 px-2"
            sort-by="calories"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import requests from "@/requests";

export default {
  name: 'CreateUser',
  data() {
    return {
      user: '',
      name: '',
      email: '',
      password: '',
      roles:[],
      showPassWord: false,
      userRules: [
        v => !!v || 'Username is required',
      ],
      emailRules: [
        v => !!v || 'Email is required',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      headers:[
        {text: 'Username',  value: 'username', align: 'center'}
      ]
    };
  },
  methods: {
    createUser() {
      if (this.$refs.form.validate()) {

        this.$store.commit('setLoadingModal', true)
        // send user data to server
        console.log(this.user, this.email, this.password)

        let token = ''
        let obj = {};
        obj.username = this.user;
        obj.password = this.password;
        obj.email = this.email;
        requests.postRequest(token, 'auth/register', obj, this.registerUserCallback)


      }
    },

    clearUserInfo(){
      this.user = '';
      this.password = '';
      this.email = '';
    },

    registerUserCallback(response) {
      this.clearUserInfo();
      this.getUsers();
      this.$store.commit('setLoadingModal', false)
    },

    getUsers(){

      this.$store.commit('setLoadingModal', true)
      requests.getUsersRequest('', this.getUserCallback)
    },
    getUserCallback(response){
      this.$store.commit('setUsers', response)
      this.$store.commit('setLoadingModal', false)

    },
    getRoles(){

      this.$store.commit('setLoadingModal', true)
      requests.getRequest('', 'role', '', this.getRolesCallback)
    },
    getRolesCallback(response){
      this.$store.commit('setRoles', response)
      this.$store.commit('setLoadingModal', false)
    }
  },
  created(){
    this.getRoles();
    this.getUsers();


  }
};
</script>
