<template>
  <div>

    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-card class="ma-2">

          <FileUploadReusable
              title="Monoverse Domains"
              file-type=".xlsx"
              number-of-sheets="1"
              :url="'upload-file/domains-file'"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileUploadReusable from "@/components/reusable/FileUploadReusable";

export default {
  name: "FileUpload",
  components: {FileUploadReusable},
  data:()=>({
    file:null,
    fileLoading:false,
    messageFile:'',
    showInfoFlag:false


  }),
  methods:{
    submitFile() {
      this.messageFile ='';
      this.fileLoading= true;
      /*
        Initialize the form data
      */
      let formData = new FormData();

      formData.append('file', this.file);
      // for (var i = 0; i < this.files.length; i++) {
      //     let file = this.files[i];
      //     formData.append('files[' + i + ']', file);
      // }

      var token = this.$store.getters.login.token;
    },
    uploadFilePostCallBack(response) {
      this.fileLoading=false;
      this.messageFile = 'Added ' +response+ ' new records';
      this.file=null;
    },
    showInfo(){
      this.showInfoFlag=true;
    },
    closeDialog(){
      this.showInfoFlag=false;
    }

  }
}
</script>

<style scoped>

</style>