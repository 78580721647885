import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{style:({ background: _vm.activeColor}),attrs:{"id":"inspire"}},[_c(VMain,[_c(VContainer,[_c(VRow,{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[_c('img',{attrs:{"width":300,"src":require("./../assets/logo.png")}})])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[(_vm.showTextFields)?_c(VCard,{staticClass:"elevation-0",style:({ background: _vm.activeColor})},[_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{ref:"name",attrs:{"color":_vm.$store.getters.settings.color.mainColor,"prepend-inner-icon":"person","label":'username',"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"color":_vm.$store.getters.settings.color.mainColor,"prepend-inner-icon":"lock","label":'password',"type":_vm.showPassWord ? 'text' : 'password',"append-icon":_vm.showPassWord ? 'visibility' : 'visibility_off',"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login()},"click:append":function($event){_vm.showPassWord = !_vm.showPassWord}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),(true)?_c(VBtn,{attrs:{"dark":"","color":_vm.$store.getters.settings.color.mainColor,"rounded":"","block":""},on:{"click":function($event){return _vm.login()}}},[_vm._v(_vm._s('login'))]):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }