import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VRow,{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"min-width":"100%","height":"60px","color":"primary","outlined":""},on:{"click":_vm.downloadXlsx}},[(_vm.isLoading)?_c(VProgressCircular,{attrs:{"indeterminate":""}}):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-download")]),_vm._v(" Domains .xlsx ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }