import axios from 'axios';
import store from './store';
import prop from './properties';
import utils from './utilities';

export const BASE_URL = process.env.VUE_APP_API_ENDPOINT;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
var requests = {


    getRequest(token, urlPart, id, callBack) {

        token = utils.getLocalStorage('token');

        axios.get( BASE_URL + 'secure/' +  urlPart + id, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': utils.bearerToken(token)
            }})
            .then(response => {
                callBack(response.data);
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },


    postRequest(token, urlPart, requestObj, callBack) {
        token = utils.getLocalStorage('token');

        axios.post( BASE_URL + 'secure/' + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': utils.bearerToken(token)
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                console.log(error)
                prop.getErrorMessage(error.response.data);
            })
    },
    postUnsecureRequest(token, urlPart, requestObj, callBack) {
        token = utils.getLocalStorage('token');

        axios.post( BASE_URL + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': utils.bearerToken(token)
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },


    putRequest(token, urlPart, requestObj, callBack) {
        console.log("PUT");
        token = utils.getLocalStorage('token');

        axios.put( BASE_URL  + 'secure/' +  urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': utils.bearerToken(token)
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },
    deleteRequest(token, urlPart, callBack) {

        token = utils.getLocalStorage('token');

        axios.delete( BASE_URL  + 'secure/' +  urlPart, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': utils.bearerToken(token)
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                console.log(error);
                prop.getErrorMessage(error.response.data);
            })
    },

    async uploadFilePostAsync(token, url, formData,uploadFilePostAsyncRespone){
        const response = await axios.post(BASE_URL  + 'secure/' +  url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': utils.bearerToken(token)
                },
                timeout: 300000
            })
        uploadFilePostAsyncRespone(response.data)
    },


    getInitGuiConfig(callBack){
        axios.get( BASE_URL + 'properties/init-gui-config/', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch(error =>{
                prop.getErrorMessage(error.response.data);
            })
    },


    login(username,password, loginCallBackSuccess) {

        var querystring = require('querystring');

        axios.post(BASE_URL + 'auth/login' , querystring.stringify({
                username: username,
                password: password,
                // creatorUserName: creator
            }),
            {
                headers: {

                    // 'Content-Type': 'application/json'
                    "Content-Type": "application/x-www-form-urlencoded"
                    // 'Authorization': 'Bearer ' + 'token'
                }})
            .then(response => {
                loginCallBackSuccess(response.data);
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },

    logout(token, logoutCallBack) {

        token = utils.getLocalStorage('token');

        axios.delete(BASE_URL + 'auth/secure/logout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': utils.bearerToken(token)
            }
        })
            .then(response => {

                logoutCallBack(response.data)
            })
            .catch(error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error);
            })
    },

    changeMyPasword(token ,password , changePassWordCallBack) {

        token = utils.getLocalStorage('token');


        var querystring = require('querystring');

        axios.put(BASE_URL + 'auth/secure/changeMyPass', querystring.stringify(
            {password: password}),
            {headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': utils.bearerToken(token)
                }})
            .then(response => {
                changePassWordCallBack(response.data)
            })
            .catch(error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },

    loginInformation(token, loginInformationCallBack){

        axios.get(BASE_URL + 'secure/auth/loginInformation',
            {headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': utils.bearerToken(token)
                }})
            .then(response =>{
                loginInformationCallBack(response.data)
            })
            .catch(error => {
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },


    getUsersRequest(token, callBack) {

        token = utils.getLocalStorage('token');

        axios.get( BASE_URL + 'secure/auth/get-users', {
            headers: {
                "Content-Type": "application/json",
                'Authorization': utils.bearerToken(token)
            }})
            .then((response => {
                callBack(response.data);
            }))
            .catch(error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },



    downloadXlsxTemplate(token, path, callback) {

        axios.get( BASE_URL +'secure'+ path,{
            responseType: 'blob',
            headers: {
                'Authorization': utils.getLocalStorage("token"),
            },
        })
            .then((response) =>{
                utils.downloadXlsx(response);
                store.commit('setLoadingModal', false);
                callback();
            })
            .catch(error =>{
                alert("something went wrong with the file.");
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data);
            })
    },

};
export default requests
