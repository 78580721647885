<template>
  <div>
    <v-card
        class="mx-auto"
        max-width="400"
        tile
    >
      <v-card-title>{{domain.title}}</v-card-title>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{domain.description}}</v-list-item-title>
          <v-list-item-subtitle> Description </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{domain.domainCategory ? domain.domainCategory.description : '-'}}</v-list-item-title>
          <v-list-item-subtitle> Category </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon v-if="domain.published" color="green">check-circle</v-icon>
            <v-icon v-else color="red">stop-circle</v-icon>
          </v-list-item-title>
          <v-list-item-subtitle> Published? </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-card>
  </div>

</template>

<script>
export default {
  name: "DomainInfo",
  props:{
    domain: Object
  }
}
</script>

<style scoped>

</style>