import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCol,{attrs:{"align":"center","justify":"center"}},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c(VFileInput,{attrs:{"clearable":"","chips":"","placeholder":"Upload your document","label":"File input","success-messages":_vm.messageFile,"prepend-icon":"mdi-file-excel","append-outer-icon":"info","loading":_vm.fileLoading},on:{"click:append-outer":function($event){return _vm.showInfo()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c(VChip,{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c(VBtn,{attrs:{"text":"","disabled":_vm.file==null},on:{"click":function($event){return _vm.submitFile()}}},[_vm._v("submit")])],1),_c(VDialog,{attrs:{"persistent":"","width":"700px"},model:{value:(_vm.showInfoFlag),callback:function ($$v) {_vm.showInfoFlag=$$v},expression:"showInfoFlag"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center primary"},[_c('span',{staticClass:"headline white--text"},[_vm._v(_vm._s('file Info'))])]),_c(VCardText,[_c('table',[_c('th'),_c('th'),_c('tr',[_c('td',[_c('h3',[_vm._v(_vm._s('File Type'))])]),_c('td',[_c('h3',[_vm._v(_vm._s(': '+_vm.fileType))])]),_c('td',[(!_vm.workOrderFile)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.downloadFile()}}},[_vm._v("template"),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1):_vm._e()],1)]),(!_vm.workOrderFile)?_c('tr',[_c('td',[_c('h3',[_vm._v(_vm._s('numberOfSheets'))])]),_c('td',[_c('h3',[_vm._v(_vm._s(': '+ _vm.numberOfSheets))])])]):_vm._e()])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.$store.getters.settings.color.mainColor,"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s('close'))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }