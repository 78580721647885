import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login";
import utils from "@/utilities";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [

    {path: '/login', name:'login', component: Login},
    {
      path: "/domains",
      alias: "domains",
      name: "domains",
      component: () => import("./components/TutorialsList")
    }, {
      path: "/",
      alias: "home",
      name: "home",
      component: () => import("./components/Home")
    },
    {
      path: "/domains/:id",
      name: "domains-details",
      component: () => import("./components/Tutorial")
    },
    {
      path: "/add",
      name: "add",
      component: () => import("./components/AddTutorial")
    },
    {
      path: "/addTemplate",
      name: "addTemplate",
      component: () => import("./components/AddTemplate")
    },
    {
      path: "/templatesList",
      name: "templatesList",
      component: () => import("./components/TemplatesList")
    },
    {
      path: "/createCustomBlock",
      name: "createCustomBlock",
      component: () => import("./components/CreateCustomBlock")
    },
    {
      path: "/mainBlock",
      name: "mainBlock",
      component: () => import("./components/MainBlock")
    },
    {
      path: "/fileUpload",
      name: "fileUpload",
      component: () => import("./components/FileUpload")
    },
    {
      path: "/createUser",
      name: "createUser",
      component: () => import("./components/CreateUser")
    },
    {
      path: "/filesDownload",
      name: "filesDownload",
      component: () => import("./components/FilesDownload")
    }
  ],
});

router.beforeEach((to, from, next) => {
  const token = utils.getLocalStorage('token')
  // If logged in, or going to the Login page.
  if ( token || to.name === 'login') {
    // Continue to page.
    next()
  } else {
    // Not logged in, redirect to login.
    next({name: 'login'})
  }
});

export default router;