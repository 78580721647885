import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from "./store";
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'


export const eventBus = new Vue();
Vue.config.productionTip = false
Vue.use(VueAxios, axios);

Vue.component("my-icon", {
  template: `<svg height="24" width="24">
        <circle cx="12" cy="12" r="9" fill="red" />
    </svg>`
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
