import Vue from 'vue';
import store from "./store";
import {BASE_URL} from "@/requests";
// const moment = require('moment')
// require('moment/locale/es')
//
// Vue.use(require('vue-moment'), {
//     moment
// })

var Utils = {

    //set the token to localStorage
    setLocalStorage(storageName, data) {
        localStorage.setItem(storageName, JSON.stringify(data));
    },

    //get the token from localStorage
    getLocalStorage(storageName) {
        return JSON.parse(localStorage.getItem(storageName));
    },

    // delete the token from localStorage
    deleteLocalStorage(storageName) {
        localStorage.removeItem(storageName);
    },

    //create the Authorization headers for request
    bearerToken(token) {
        return 'Bearer ' + token
    },


    downloadXlsx(response){

        let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml"
        }); // 2. Get the blob setting file type in the response object returned by the request. Here is excel as an example.
        let url = window.URL.createObjectURL(blob); // 3. Create a temporary url pointing to the blob object

        // 4. After creating the url, you can simulate a series of operations on this file object, for example: preview, download
        let a = document.createElement("a");
        a.href = url;
        a.download = response.headers.filename;
        a.click();
        // 5. Release this temporary object url
        window.URL.revokeObjectURL(url);
    },


};

export default Utils
