import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align-content":"stretch"}},[_c(VCol,[_c('drag',{attrs:{"data":_vm.selected},scopedSlots:_vm._u([{key:"drag-image",fn:function(){return [_c(VBadge,{staticStyle:{"transform":"translate(10px, 5px)"},attrs:{"color":"red"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(_vm._s(_vm.selected.length))]},proxy:true}])},[_c(VIcon,{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-food-variant")])],1)]},proxy:true}])},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.desserts,"item-key":"name","show-select":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c(VCol,[_c('drop',{staticClass:"my-list",staticStyle:{"height":"100%"},attrs:{"tag":"v-list","three-line":""},on:{"drop":_vm.onDrop}},[_vm._l((_vm.lists),function(list,index){return [_c(VListItem,{key:index,on:{"click":function($event){}}},[_c(VListItemAvatar,{attrs:{"color":"primary","size":"48"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(list.length))])]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("List "+_vm._s(index))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(list.map(function (l) { return l.name; }).join(", "))),_c('br'),_vm._v(" "+_vm._s(list.map(function (l) { return l.calories; }).join(", "))+" ")])],1)],1),_c(VDivider)]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }