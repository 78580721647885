<template>
  <div>
    <v-app>
    <v-dialog fullscreen hide-overlay max-width="500px" persistent transition="dialog-bottom-transition"
              v-model="$store.getters.editOrAddDomainModal">

      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">{{formTitle}}</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="white" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <v-container>
            <v-card class="elevation-6 mt-5">
              <v-card-text>

                <v-form ref="form" v-model="valid">

                  <v-row align="center" justify="center" >
                    <v-col cols="2">
                      <h3>Main Info</h3>
                    </v-col>
                  </v-row>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col cols="3">
                    <v-text-field color="blue"
                                  :disabled="disableFields"
                                  label="Domain"
                                  outlined
                                  type="text"
                                  v-model="tutorial.title"></v-text-field>
                  </v-col>

                  <v-col cols="3">

                    <v-select :color="$store.getters.settings.color.mainColor"
                              :disabled="disableFields"
                              :item-color="$store.getters.settings.color.mainColor"
                              :items="$store.getters.tlds"
                              :label="'TLD'"
                              chips
                              outlined
                              :height="40"
                              item-text="description"
                              persistent-hint
                              return-object
                              :rules="myRules.required"
                              v-model="tutorial.tld"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row  align="center" justify="center">
                  <v-col cols="3">

                    <v-select :color="$store.getters.settings.color.mainColor"
                              :disabled="disableFields"
                              :item-color="$store.getters.settings.color.mainColor"
                              :items="$store.getters.blockOwners"
                              :label="'Block Owner'"
                              chips
                              outlined
                              :height="40"
                              item-text="description"
                              persistent-hint
                              return-object
                              :rules="myRules.required"
                              v-model="tutorial.blockOwner"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">

                    <v-select :color="$store.getters.settings.color.mainColor"
                              :disabled="disableFields"
                              :item-color="$store.getters.settings.color.mainColor"
                              :items="$store.getters.mainBlocks"
                              :label="'Main Block'"
                              chips
                              outlined
                              :height="40"
                              item-text="description"
                              persistent-hint
                              return-object
                              :rules="myRules.required"
                              v-model="tutorial.mainBlock"
                    ></v-select>
                  </v-col>


                </v-row>
                  <v-row align="center" justify="center" >
                    <v-col cols="2">
                      <h3>SEO Info</h3>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Meta Title"
                                    outlined
                                    type="text"
                                    v-model="tutorial.metaTitle"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Meta Description"
                                    outlined
                                    type="text"
                                    v-model="tutorial.metaDescription"></v-text-field>
                    </v-col>


                  </v-row>

                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-text-field color="blue"
                                  :disabled="disableFields"
                                  label="Headline Text"
                                  outlined
                                  type="text"
                                  v-model="tutorial.headlineText"></v-text-field>
                  </v-col>


                  <v-col cols="3">
                    <v-text-field color="blue"
                                  :disabled="disableFields"
                                  label="Headline Text 2"
                                  outlined
                                  type="text"
                                  v-model="tutorial.headlineText2"></v-text-field>
                  </v-col>

                </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="2">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Hastag 1"
                                    outlined
                                    type="text"
                                    v-model="tutorial.hashtag1"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Hastag 1"
                                    outlined
                                    type="text"
                                    v-model="tutorial.hashtag2"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Hastag 1"
                                    outlined
                                    type="text"
                                    v-model="tutorial.hashtag3"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="GMT ID"
                                    outlined
                                    type="text"
                                    v-model="tutorial.gtmId"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Mesurement ID"
                                    outlined
                                    type="text"
                                    v-model="tutorial.measurementId"></v-text-field>
                    </v-col>

                  </v-row>

                  <v-row align="center" justify="center" >
                    <v-col cols="2">
                      <h3>Pricing</h3>
                    </v-col>
                  </v-row>


                  <v-row align="center" justify="center">

                    <v-col cols="3">
                      <v-text-field color="blue"
                                    :disabled="disableFields"
                                    label="Price"
                                    outlined
                                    type="number"
                                    :rules="[numberRule]"
                                    v-model="tutorial.price"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-row>
                        <v-switch
                                v-model="tutorial.forSale"
                                :label="'Available for sale'"
                        ></v-switch>
                        <v-switch
                                v-model="tutorial.offer"
                                :label="'Offer'"
                        ></v-switch>
                      </v-row>

                    </v-col>


                  </v-row>

<!--                <v-row align="center" justify="center">-->
<!--                  <v-col cols="2">-->
<!--                    <v-radio-group v-model="radioGroup">-->
<!--                      <v-radio-->
<!--                          color="blue"-->
<!--                          v-for="option in radioOptions"-->
<!--                          :key="option.id"-->
<!--                          :label="`${option.description}`"-->
<!--                          :value="option.id"-->
<!--                      ></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->
<!--                  -->
<!--                </v-row>-->

                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn @click="saveTutorial()" :disabled="disableFields || !valid " color="primary">Save</v-btn>
                  <v-btn class="ml-2" :disabled="!valid || editedIndex === -1" @click=" generateWebsite()" color="purple">generate</v-btn>

              </v-card-actions>
            </v-card>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-app>
  </div>



<!--  <div class="submit-form">-->
<!--    <div v-if="!submitted">-->
<!--      <div class="form-group">-->
<!--        <label for="title">Template Name</label>-->
<!--        <input-->
<!--          type="text"-->
<!--          class="form-control"-->
<!--          id="title"-->
<!--          required-->
<!--          v-model="tutorial.title"-->
<!--          name="title"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label for="description">Description</label>-->
<!--        <input-->
<!--          class="form-control"-->
<!--          id="description"-->
<!--          required-->
<!--          v-model="tutorial.description"-->
<!--          name="description"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <label for="content">content</label>-->
<!--        <input-->
<!--          class="form-control"-->
<!--          id="content"-->
<!--          required-->
<!--          v-model="tutorial.content"-->
<!--          name="content"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="form-group mt-2">-->
<!--        <v-select-->
<!--            :items="$store.getters.templates"-->
<!--            :height="40"-->
<!--            v-model="tutorial.template"-->
<!--            label="Select Template"-->
<!--            item-text="description"-->
<!--            chips-->
<!--            outlined-->
<!--            return-object-->
<!--            persistent-hint>-->

<!--        </v-select>-->
<!--      </div>-->

<!--      <button @click="saveTutorial" class="btn btn-success">Submit</button>-->
<!--    </div>-->

<!--    <div v-else>-->
<!--      <h4>You submitted successfully!</h4>-->
<!--      <button class="btn btn-success" @click="newTutorial">Add</button>-->
<!--    </div>-->



<!--  </div>-->
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
import request from '../requests';
import requests from "../requests";
import {eventBus} from "@/main";
export default {
  name: "add-tutorial",
  data() {
    return {
      valid: true,
      tutorial: {
        id: null,
        title: "",
        description: "",
        content: "",
        published: false,
        price:"",
        // hastag1:"",
        // hastag2:"",
        // hastag3:""
      },
      radioGroup: 1,
      radioOptions:[
          {id:1, description: 'Flat'},
          {id:2, description: 'House'}
      ],
      category:1,
      myRules: {
        required: [value => !!value || "Required."]
      },
      submitted: false,
      numberRule: val => {
        if(val < 0) return 'Please enter a positive number'
        return true
      }
    };
  },
  props: {
    editedIndex: Number,
    disableFields:Boolean,
  },

  methods: {
    saveTutorial() {

      // requests.postRequest('', 'api/tutorials', this.tutorial, this.addNewDomainCallback)

      if ((typeof this.tutorial.price === 'string' || this.tutorial.price instanceof String)){
        this.tutorial.price = Number.parseFloat(this.tutorial.price)
      }else{

      }

      if(this.radioGroup === 1){
        this.tutorial.flat = true;
        this.tutorial.house = false;
      }else{
        this.tutorial.house = true;
        this.tutorial.flat = false;
      }

      console.log("price: " +this.tutorial.price);

      if (this.editedIndex > -1) {
        console.log("edit");
        //Edit user

        this.$store.commit('setLoadingModal', true);
        // console.log(this.tutorial)
        requests.putRequest('', 'api/edit-domain', this.tutorial, this.editDomainCallback)
      } else {
        // Add new user
        console.log("add");
        this.$store.commit('setLoadingModal', true);
        requests.postRequest('', 'api/add-domain', this.tutorial, this.addNewDomainCallback)
      }
      this.closeDialog()

    },



    addNewDomainCallback(response){
      this.tutorial.id = response.id;
      console.log(response);
      this.submitted = true;


      this.$store.commit('setLoadingModal', false);
    },
    editDomainCallback(response){
      this.tutorial.id = response.id;
      console.log(response);

      this.$store.commit('setLoadingModal', false);
    },
    newTutorial() {
      this.submitted = false;
      this.tutorial = {};
    },
    getFK(){
      this.$store.commit('setLoadingModal',true);
      var token = '';
      requests.getRequest(token, 'template', '' ,this.getFKCallBack);
      requests.getRequest(token, 'main-block', '' ,this.getMainBlocksCallBack);
      requests.getRequest(token, 'tld', '' ,this.getTldsCallBack);
      requests.getRequest(token, 'block-owner', '' ,this.getBlockOwnersCallBack);
    },
    getMainBlocksCallBack(response){
      this.$store.commit('setMainBlocks',response);

    },
    getTldsCallBack(response){
      this.$store.commit('setTlds',response);
    },
    getBlockOwnersCallBack(response){
      this.$store.commit('setBlockOwners',response);

      this.$store.commit('setLoadingModal', false);
    },

    getFKCallBack(response) {
      this.$store.commit('setTemplates',response);
      // console.log(response)

    },

    closeDialog() {
      this.$store.commit('setEditOrAddDomainModal', false);
    },
    createDomainObj(data) {
      this.tutorial = JSON.parse(JSON.stringify(data));
      if(Object.keys(this.tutorial).length>0){
        this.radioGroup = this.tutorial.flat===true ? 1 : 2
        // this.tutorial.hastag1
        // console.log(this.tutorial.flat)
      }

    },
    generateWebsite(){
      this.$store.commit('setLoadingModal',true);
      var token = '';
      var obj = {};
      obj.domainId = this.tutorial.id
      requests.postRequest(token, 'api/generate-website/', obj, this.postGenerateWebsiteCallback);

    },
    postGenerateWebsiteCallback(response){
      this.$store.commit('setLoadingModal',false);
      this.closeDialog();

    },
  },
  created() {
    this.getFK();


    eventBus.$on('createDomainObj', (unit,searchObj) => {

      this.createDomainObj(unit)
    });
    if(this.editedIndex === 0){
      this.radioGroup = this.tutorial.flat===true ? 1 : 2
      // console.log(this.tutorial.flat)
    }


  },
  beforeDestroy() {
    eventBus.$off('createDomainObj')
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Add New Domain' : this.editedIndex === -2 ? 'Domain Info' : 'Edit Domain'
    },
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
