<template>
  <div>

    <v-col align="center" justify="center">
      <h3>{{title}}</h3>
      <v-file-input
          v-model="file"
          clearable
          chips
          placeholder="Upload your document"
          label="File input"
          :success-messages="messageFile"
          prepend-icon="mdi-file-excel"
          append-outer-icon="info"
          @click:append-outer="showInfo()"
          :loading="fileLoading"

      >
        <template v-slot:selection="{ text }">
          <v-chip
              small
              label
              color="primary"
          >
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-btn text :disabled="file==null" @click="submitFile()">submit</v-btn>
    </v-col>

    <v-dialog persistent width="700px" v-model="showInfoFlag">
      <v-card>
        <v-card-title class="justify-center primary">
          <span class="headline white--text">{{'file Info'}}</span>
        </v-card-title>
        <v-card-text>
          <table>
            <th></th>
            <th></th>
            <tr>
              <td> <h3>{{'File Type'}}</h3></td>
              <td> <h3>{{': '+fileType}}</h3></td>
              <td> <v-btn v-if="!workOrderFile" @click="downloadFile()" text>template<v-icon color="primary">mdi-download</v-icon></v-btn></td>
            </tr>
            <tr v-if="!workOrderFile">
              <td> <h3>{{'numberOfSheets'}}</h3></td>
              <td> <h3>{{': '+ numberOfSheets}}</h3></td>
            </tr>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" :color="$store.getters.settings.color.mainColor" text>{{'close'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import request from "../../requests";
import {eventBus} from "@/main";

export default {
  name: "FileUploadReusable.vue",
  data:()=>({
    file:null,
    fileLoading:false,
    messageFile:'',
    showInfoFlag:false
  }),
  props:{
    title: String,
    url:String,
    fileType:String,
    numberOfSheets: String,
    eventToEmitOnComplete: String,
    columns:Array,
    workOrderFile : Boolean,
    workOrderId: Number
  },
  methods:{
    downloadFile(){
      this.$store.commit('setLoadingModal', true)
      var token = this.$store.getters.login.token;
      let obj={}
      obj.columns=this.columns
      obj.title=this.title
      // request.downloadXlsxTemplate(token,'download-file/get-xlsx/template', obj)
    },
    fileCallback(){

    },

    submitFile() {
      this.messageFile ='';
      this.fileLoading= true;
      /*
        Initialize the form data
      */
      let formData = new FormData();

      formData.append('file', this.file);
      // for (var i = 0; i < this.files.length; i++) {
      //     let file = this.files[i];
      //     formData.append('files[' + i + ']', file);
      // }
      if(this.workOrderFile){
        formData.append('workOrderId', Number.parseInt(this.workOrderId));
      }


      var token = this.$store.getters.login.token;
      // request.uploadFilePost(token, this.url, formData, this.uploadFilePostCallBack)
      request.uploadFilePostAsync(token, this.url, formData, this.uploadFilePostCallBack)
    },
    uploadFilePostCallBack(response) {
      this.fileLoading=false;
      this.messageFile = 'Added ' +response+ ' new records';
      this.file=null;
      if (this.eventToEmitOnComplete) {
        eventBus.$emit(this.eventToEmitOnComplete, response);
      }
    },
    showInfo(){
      this.showInfoFlag=true;
    },
    closeDialog(){
      this.showInfoFlag=false;
    }

  }
}
</script>

<style scoped>

</style>
