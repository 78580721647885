import http from "../http-common";
export const BASE_URL = process.env.VUE_APP_API_ENDPOINT;
class TutorialDataService {
  getAll() {
    return http.get(BASE_URL +"/tutorials");
  }

  get(id) {
    return http.get(BASE_URL +`/tutorials/${id}`);
  }

  create(data) {
    return http.post(BASE_URL +"/tutorials", data);
  }

  update(id, data) {
    return http.put(BASE_URL +`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(BASE_URL +`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(BASE_URL +`/tutorials`);
  }

  findByTitle(title) {
    return http.get(BASE_URL +`/tutorials?title=${title}`);
  }
}

export default new TutorialDataService();
