import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mb-1"},[_c(VCol,{staticClass:"mx-auto text-center font-weight-light headline"},[_c('div',[_vm._v(" "+_vm._s('Main Block')+" ")])])],1),_c(VRow,[_c(VCol,{staticClass:"pt-1"},[_c(VDataTable,{staticClass:"elevation-3 mx-5 px-2",attrs:{"footer-props":{itemsPerPageOptions:[5,10,15,30]},"headers":_vm.headers,"items":_vm.$store.getters.mainBlocks,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VCard,{staticClass:"mb-5",attrs:{"color":_vm.$store.getters.settings.color.mainColor,"height":"80","width":"90"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:" mt-4  text-center white--text"},[_vm._v(" "+_vm._s('Main Block')+" ")])],1)],1),_c(VToolbarTitle,[_c('br'),_c(VTextField,{staticClass:"ml-5 mb-1",attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VDialog,{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mb-2",attrs:{"color":_vm.$store.getters.settings.color.mainColor,"dark":"","fab":"","small":""},on:{"click":_vm.addButton}},on),[_c(VIcon,[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s('Add'))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary"},[_c('span',{staticClass:"headline white--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"color":_vm.$store.getters.settings.color.mainColor,"label":'Description'},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"color":_vm.$store.getters.settings.color.mainColor,"dense":"","outlined":"","label":'Body'},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.$store.getters.settings.color.mainColor,"text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s('close')+" ")]),_c(VBtn,{attrs:{"color":_vm.$store.getters.settings.color.mainColor,"text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s('save')+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Edit'))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.openModalToDeleteItem(item)}}},on),[_vm._v("delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('delete'))])])]}}])})],1)],1),_c('DeleteDialog',{attrs:{"eventName":"MainBlockDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }