<template>
  <div>


    <v-data-table
        class="elevation-6 mx-5 px-2"
        :footer-props="{itemsPerPageOptions:[5,10,15,30]}"
        :items="$store.getters.templatesList"
        :headers="headers">
      <template v-slot:item="{ item }">
        <tr>
         <td class="text-center d-sm-table-cell d-flex">{{ item.description!="" ? item.description : '--' }}</td>

          <td class="text-center d-sm-table-cell d-flex">{{ item.boxedContainers!=null ? item.boxedContainers.length : '--' }}</td>
          <td class="text-center d-sm-table-cell d-flex">
            <v-btn @click="edit(item)" icon small color="green"><v-icon>edit</v-icon></v-btn>
            <v-btn @click="openModalToDeleteItem(item)" icon small color="red"><v-icon>delete</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog @keydown.esc="closeDialog"  v-if="selectedItem!=null" max-width="900px" persistent v-model="showDialog">
      <v-card>
        <v-col cols="1" aling="right">
          <v-btn @click="closeDialog"><v-icon color="primary">close</v-icon></v-btn>
        </v-col>
        <v-card-title>{{selectedItem.description}}</v-card-title>
        <v-card-text>

          <v-card class="mx-2 mt-2" color="cyan lighten-4" v-for="(box, index_bc) in selectedItem.boxedContainers" :key="index_bc">
            <v-card-title>Box {{ index_bc+1 }}</v-card-title>
            <v-col cols="2">
            </v-col>
            <v-row v-for="(field, index) in box.fields" :key="index">
              <v-col class="text-center d-sm-table-cell d-flex" cols="4">
                <h3>{{field.fieldType.title}}</h3>
              </v-col>  <v-col class="text-center d-sm-table-cell d-flex" cols="2">
                <h5>{{field.fieldType.description}}</h5>
              </v-col>

              <v-col class="text-center d-sm-table-cell d-flex" cols="2">
                <h3>{{field.description}}</h3>
              </v-col>
              <v-col class="text-center d-sm-table-cell d-flex" v-if="field.fieldType.id !==3 && field.fieldType.id !==4" cols="2">
                {{field.value}}
              </v-col>
            </v-row>
          <br/>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DeleteDialog :eventName="'DeleteTemplateFromTemplateList'"/>

  </div>
</template>

<script>
import requests from "@/requests";

import {eventBus} from "@/main";
import DeleteDialog from "@/components/reusable/DeleteDialog";
export default {
  name: "TemplatesList",
  components:{DeleteDialog},
  data() {
    return {
      selectedItem:null,
      showDialog:false,
      deleteTemplateButton:false,
      deleteObj:null,
      headers:[
        {text: 'Description', value: '', sortable: true, align: 'center'},
        {text: 'Boxed Containers', value: '', sortable: false, align: 'center'},
       {text: 'Actions', value: 'actions', sortable: false, align: 'center'},

      ],
    }
  },
  methods:{
    openModalToDeleteItem(item) {
      this.selectedItem=item;
      this.deleteObj = this.selectedItem;
      this.deleteTemplateButton=true
      this.$store.commit('setDeleteDialog', true);
    },

    getTemplatesList(){

      this.$store.commit('setLoadingModal',true);
      requests.getRequest('', 'template/list', '', this.getTemplatesCallBack)

    },
    getTemplatesCallBack(response){
      this.$store.commit('setTemplatesList', response.items);
      this.$store.commit('setLoadingModal',false);
    },
    edit(item){
      this.selectedItem =item
      this.showDialog = true;

    },
    deleteItem(item){

    },
    closeDialog(){
      this.showDialog=false;
    }
  },
  created() {
    this.getTemplatesList();
    eventBus.$on('DeleteTemplateFromTemplateList', (() => {
      this.deleteItem();
      this.deleteTemplateButton=false;
    }));
  }
}
</script>

<style scoped>

</style>